<template>
	<div
		class="vx-row h-screen lg:flex w-full no-gutter"
		id="page-login"
		>
		<div
      class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full bg-blue"
      :class="`${view === 'login' ? 'hidden lg:block' : ''}`"
      >
			<div class="flex justify-center items-center h-full w-full">
				<div>
          <enter-event-code />

          <div class="mt-20 block lg:hidden">
            <h4 class="text-white cursor-pointer" @click="setView('login')">{{ $t('Recover Password') }} </h4>
          </div>
        </div>
			</div>
		</div>
		<div
      class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full m-4 lg:m-0"
      :class="`${view === 'code' ? 'hidden lg:block' : ''}`"
      >
			<div class="flex items-center justify-center h-full w-full">
        <div>
          <div class="lg:p-10 max-w-lg lg:max-w-md mx-auto login-wrapper w-full">
            <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/3"/>
            <vx-card class="rounded-md">
              <div slot="no-body" class="full-page-bg-color">
                <div class="vx-row no-gutter justify-center items-center">
                  <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
                    <div class="p-8 mb-10 mt-10">
                      <div class="vx-card__title mb-8">
                        <h4 class="mb-4">{{ $t('Reset Password') }}</h4>
                        <p>{{ $t('Please enter your new password') }}.</p>
                      </div>

                      <vs-input v-filter-input
                        type="email"
                        class="w-full mb-8"
                        :label-placeholder="$t('Email')"
                        v-model="email"
                        readonly />

                      <vs-input
                        type="password"
                        class="w-full mb-8"
                        name="password"
                        :label-placeholder="$t('New Password')"
                        v-model="password"
                        v-validate="'required|min:8'"
                      />
                      <span
                        class="text-danger text-sm"
                      >{{ $t(errors.first('password')) }}</span>

                      <vs-input
                        type="password"
                        class="w-full"
                        name="confirm-password"
                        :label-placeholder="$t('Confirm Password')"
                        v-model="passwordConfirmation"
                        v-validate="'required|min:8'"
                      />
                      <span
                        class="text-danger text-sm"
                      >{{ $t(errors.first('confirm-password')) }}</span>

                      <div class="mt-6 flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                        <vs-button type="border" to="/login" class="px-4 w-full md:w-auto">{{ $t('Back To Login') }}</vs-button>

                        <vs-button
                          class="w-full sm:w-auto"
                          :class="{ 'is-loading': isResetting }"
                          :disabled="!isFormValid"
                          @click="resetPassword">
                          {{ $t('Change Password') }}
                        </vs-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vx-card>

            <!-- <div class="text-center mt-5">
              Haven't signed up yet? <router-link to="/register">{{ $t('Create an account') }}</router-link>
            </div> -->
          </div>

          <div class="mt-20 block lg:hidden">
            <h4 class="cursor-pointer" @click="setView('code')">{{ $t('Enter event code') }} </h4>
          </div>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import EnterEventCode from '@/components/events/EnterEventCode.vue';
import authApi from '../../api/auth';
import { getAxiosErrorMessage } from '../../lib/helper';

export default {
  components: {
    EnterEventCode,
  },
  data() {
    return {
      // email: '',
      password: '',
      passwordConfirmation: '',
      isResetting: false,
    };
  },
  methods: {
    resetPassword() {
      this.isResetting = true;
      const params = {
        email: this.email,
        token: this.token,
        new_password: this.password,
        confirm_password: this.passwordConfirmation,
      };
      const callback = (response) => {
        const message = response.message;
        this.$vs.notify({
          title: 'Reset Password',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isResetting = false;
        this.$router.push('/login');
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: 'Reset Password',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isResetting = false;
      };
      authApi.resetPassword(params, callback, errorCallback);
    },
  },
  computed: {
    isFormValid() {
      return this.password !== '' && this.passwordConfirmation !== '' && this.password === this.passwordConfirmation;
    },
    email() {
      return this.$route.query.email || '';
    },
    token() {
      return this.$route.query.token || '';
    },
    lang() {
      return this.$route.query.lang || 'en';
    },
  },
};
</script>
